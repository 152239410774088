<template>
  <div style="margin-bottom: 100px;">
      <!-- <div style="position: fixed; bottom: 15%;z-index: 99;right: 10%" v-show="data">
        <q-btn @click="$emit('backList')" color="secondary">返回上一级</q-btn>
      </div> -->
    <div>
      <q-card style="padding: 40px;">
        <div v-show="data" style="margin-bottom: 40px;">
          <q-breadcrumbs separator='>' gutter="md" style="margin-top: 20px">
            <q-breadcrumbs-el label="产业链列表" style="cursor: pointer" @click="$router.push({name:'chainIndex'})" />
            <q-breadcrumbs-el :label="$route.params.name" />
          </q-breadcrumbs>
        </div>
        <div v-html="data"></div>
      </q-card>
      <q-card style="margin-top: 2%" ref="searchPart" v-show="isChainSearchShow">
        <search
          :fromChain="true"
          :chainSearchData="chainSearchData"
          @chainSearchFinish="chainSearchFinish"
          :chainData="chainData"
        />
      </q-card>
    </div>
    <!--
        @小丁
        2022 1.6
        弹窗闪动bug请修改quasar框架 qmenu.js文件
        this.__refocusTarget.focus() 注释此行代码
     -->
    <q-popup-proxy :value='tipShow' @hide='popupTableHide({})' :target="tipDom" @mouseover="mouseoverTable" @mouseout="mouseoutTable" no-parent-event>
        <q-table
          :title="tableTitle"
          :data="nowTipData"
          row-key="name"
          :columns="columns"
          color='primary'
          hide-pagination
          no-data-label='该节点暂无数据'
          :loading='tableLoading'
        >
          <template v-slot:body="props">
            <q-tr :props="props" @click="popupTabelClick(props)" style="cursor: pointer;">
              <q-td key="type" :props="props">
                {{ props.row.type }}
              </q-td>
              <q-td key="count" :props="props" :style="{ color: props.row.count == 0 ? 'rgba(0, 0, 0, 0.4)' : '#40a9ff' }">
                  {{ props.row.count }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      <q-inner-loading :showing="false">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-popup-proxy>

      <q-dialog v-model="popupTableItemInfo_show" transition-show='slide-down' transition-hide='slide-down'>
      <q-card style="min-width: 1000px;display: flex;flex-direction: column;" >
        <div class="tableInfo_table" style="min-height: 300px;padding: 20px">
          <q-table
            :title="popupTableItemInfo_tableTitle"
            :data="popupTableItemInfo_Data.list"
            row-key="nameHighlighted"
            :columns="popupTableItemInfo_columns"
            color='primary'
            flat
            :table-header-style="{ backgroundColor: 'rgba(206, 206, 206,0.1)' }"
            hide-pagination
            no-data-label='该节点暂无数据'
            :pagination='{ rowsPerPage: popupTableItemInfo_Data.pageSize, rowsNumber: popupTableItemInfo_Data.pages }'
            :table-style='{ color: "rgba(0,0,0,0.7)" }'
          >
            <template v-slot:top>
              <div style="display: flex;justify-content: space-between;width: 100%;align-items: center">
                <div class="tableInfo_tableTitle">{{popupTableItemInfo_tableTitle}}</div>
                <div style="width: 30%">
                  <q-select
                    transition-show="scale"
                    transition-hide="scale"
                    dense
                    v-model="popupTableItemInfo_select"
                    label="查看企业行业分类"
                    @input="popupTableItemInfo_selectClass"
                    :options='popupTableItemInfo_Data.industryBucket'
                    option-label="name"
                    >
                    <template v-slot:option='{opt, itemProps, itemEvents}'>
                      <q-item v-bind="itemProps" v-on="itemEvents">
                        <q-item-section>
                          {{opt.name}}{{opt.count ? '(' + opt.count + '）' : ''}}
                        </q-item-section>
                      </q-item>
                    </template>
                    <template v-slot:no-option>
                      <q-item>
                        <q-item-section class="text-italic text-grey">
                          无检索结果
                        </q-item-section>
                      </q-item>
                    </template>
                    </q-select>
                </div>
              </div>
            </template>
            <template v-slot:body="props">
            <q-tr :props="props" @click="jumpToGraph(props)" style="cursor: pointer;">
              <q-td key="name" :props="props" style="color: #40a9ff">
                {{ props.row.nameHighlighted }}
              </q-td>
              <q-td v-html="props.row.legalRep" key="legalRep" :props="props">
              </q-td>
              <q-td key="manageStatus" :props="props">
                  {{ props.row.manageStatus }}
              </q-td>
              <q-td key="regCapital" :props="props">
                  {{ props.row.regCapital }}
              </q-td>
              <q-td key="approvalDate" :props="props">
                  {{ props.row.approvalDate }}
              </q-td>
            </q-tr>
          </template>
          </q-table>
          <div style="margin: 10px 0;display: flex;justify-content: center;">
            <q-pagination
            @input="popopTableItemInfo_changePage"
            v-if="popupTableItemInfo_Data.pages > 1"
            v-model="popupTableItemInfo_Data.pageNo"
            :max="popupTableItemInfo_Data.pages"
            direction-links
            :max-pages="6"
            boundary-numbers
          />
          </div>
        </div>
        <div class="tableInfo_control" style="height: 60px">
          <q-btn style="position: absolute;right: 8%" color="primary" label="关闭" @click="popupTableItemInfo_show = false" />
        </div>
        <q-inner-loading  :showing="popupTableItemInfo_loading">
          <q-spinner-gears size="50px" color="primary" />
        </q-inner-loading>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import '../../public/chain_company_patent/index'
import { getChainInfo } from "@/api/get-json";
import search from "../views/Search.vue";

export default {
  name: "ChainDetail",
  components: { search },
  mounted() {
    this.$q.loading.show({
      message: "加载创新链中..."
    });
    this.axios
      .get(
        process.env.VUE_APP_API_BASE_URL +
          "/chain/detail?id=" +
          this.$route.params.id
      )
      .then(res => {
        this.$q.loading.hide();
        if (res.data.code === 20000) {
          this.axios
            .get("/chaindata/" + this.$route.params.id + ".json")
            .then(res => {
              this.chainData = res.data.data;
            })
            .catch(() => {
              console.log("此产业链无导航结构");
              this.data = res.data.data.content;
              this.$nextTick(() => {
                window.init(res.data.data.source);
                this.initPopup();
                window.addEventListener("mainColorCilck", e => {
                  this.chainSearchData.keyword = e.detail.keyWord;
                });
              });
            });
        } else {
          this.$q
            .dialog({
              title: "提示",
              message: res.data.msg
            })
            .onOk(() => {
              this.$router.back();
            });
        }
      })
      .catch(() => {
        // console.log(111)
      });
  },
  data() {
    return {
      data: "",
      chainData: [],
      chainSearchData: {
        keyword: "暂无数据",
        ipc: ""
      },
      tipDom: "",
      tipShow: false,
      isChainSearchShow: false,

      // 所有的tip数据
      allTipsData: {},
      nowTipData: [],
      tableTitle: "暂无数据",
      columns: [
        {
          name: 'type',
          required: true,
          label: '类型',
          align: 'left',
          field: row => row.type,
          format: val => `${val}`,
          sortable: true,
          headerStyle: 'width: 280px'
        },
        { name: 'count', label: '数量', field: 'count', sortable: true }
      ],
      popupTableItemInfo_columns: [
        { name: 'name', label: '企业名称', align: 'left', field: 'name', sortable: true, headerStyle: 'font-size: 14px;color: #000' },
        { name: 'legalRep', label: '法定代表人', align: 'left', field: 'legalRep', sortable: true, headerStyle: 'font-size: 14px;color: #000' },
        { name: 'manageStatus', label: '状态', align: 'left', field: 'manageStatus', sortable: true, headerStyle: 'font-size: 14px;color: #000' },
        { name: 'regCapital', label: '注册资本', align: 'left', field: 'regCapital', sortable: true, headerStyle: 'font-size: 14px;color: #000' },
        { name: 'approvalDate', label: '成立日期', align: 'left', field: 'approvalDate', sortable: true, headerStyle: 'font-size: 14px;color: #000' }
      ],
      nowTimeOutId: '',
      dialogFlag: false,
      tableLoading: false,
      popupTableItemInfo_show: false,
      popupTableItemInfo_loading: false,
      popupTableItemInfo_Data: {
        list: [],
        total: 0,
        pages: 1,
        pageNo: 1,
        pageSize: 8
      },
      popupTableItemInfo_select: '',
      popupTableItemInfo_tableTitle: '',
      popupTableItemInfo_pagination: {
        rowsPerPage: 8,
        rowsNumber: 300
      },
      popupTableItemInfo_reqData: {}
    };
  },
  methods: {
    getChainGraph(args, callback) {
      getChainInfo(args).then(response => {
        // console.log(response)
        this.data = response;
        if (callback) {
          this.$nextTick(callback());
        }
      });
    },
    chainSearchFinish () {
      this.isChainSearchShow = true
      this.$nextTick(() => {
      let top = this.$refs.searchPart.$el.offsetTop
      setTimeout(() => {
        document.documentElement.scrollTo({
          top,
          left: 0,
          behavior: 'smooth'
        })
      },500)
      })
    },
    initPopup () {
      let allRectNode = []
      // let allGNode = document.querySelectorAll('g')
      let allTextNode = document.querySelectorAll('foreignObject')
      // console.log(allGNode)
      for(let i = 0; i < allTextNode.length; i++) {
        let textItem = allTextNode[i]
        let textFath = textItem.parentElement.parentElement
        allRectNode.push(this.getGRectNode(textFath))
      }
      for (let i = 0; i < allTextNode.length; i++) {
        let textNode = allTextNode[i]
        let rectNode = allRectNode[i]
        let text = textNode.textContent
        textNode.addEventListener('mouseover', async () => {
          clearTimeout(this.nowTimeOutId)
          this.dialogFlag = true
        })

        rectNode.addEventListener('mouseover', async () => {
          clearTimeout(this.nowTimeOutId)
          if (this.dialogFlag && text === this.tableTitle) {
            return
          } else {
            this.nowTipData = []
            this.tableLoading = true
            let data = []
            this.tipShow = false
            this.tipDom = ''
            this.tipShow = true
            this.tipDom = rectNode
            this.tableTitle = text
          await this.axios.get('https://1.ink/api/company/businessScope/eggs?keyword=' + text)
            .then(res => {
              // console.log(res)
              if (res.data.status === 0) {
                for (let key in res.data.result) {
                  if (key === 'total') data.push({ type: '相关企业数', count: res.data.result.total, typeCount: 0 })
                  else if (key === 'patent') data.push({ type: '含有专利企业数量', count: res.data.result.patent, typeCount: 1 })
                  else if (key === 'brand') data.push({ type: '含有商标企业数量', count: res.data.result.brand, typeCount: 2 })
                }
              }
            })
          this.tableLoading = false
          this.nowTipData = data
          this.dialogFlag = true
          }
        })

        rectNode.addEventListener('mouseout', () => {
          this.nowTimeOutId = setTimeout(() => {
            this.tipShow = false
            this.tipDom = ''
            this.tableTitle = '暂无数据'
            this.nowTipData = []
            this.dialogFlag = false
          }, 100)
        })

        textNode.addEventListener('mouseout', () => {
          this.nowTimeOutId = setTimeout(() => {
            this.tipShow = false
            this.tipDom = ''
            this.tableTitle = '暂无数据'
            this.nowTipData = []
            this.dialogFlag = false
          }, 100)
        })
      }
    },
    popupTableHide () {
      return false
    },
    // 迭代处理dom
    getGRectNode (nowNode) {
      if (nowNode.previousSibling) {
        if (nowNode.previousSibling.childNodes.length !== 0) {
          let flag = false
          for (let i = 0 ; i < nowNode.previousSibling.childNodes.length; i++) {
            if (nowNode.previousSibling.childNodes[0].nodeName === 'rect') flag = true
          }
          if (flag) {
            return nowNode.previousSibling
          } else {
            return this.getGRectNode(nowNode.previousSibling)
          }
        } else {
          return this.getGRectNode(nowNode.previousSibling)
        }
    	}
    },
    // 鼠标移过表格
    mouseoverTable () {
      if (this.nowTimeOutId) {
        clearTimeout(this.nowTimeOutId)
        this.nowTimeOutId = null
        this.dialogFlag = true
      }
    },
    // 鼠标移出表格
    mouseoutTable () {
      this.nowTimeOutId = setTimeout(() => {
        this.tipShow = false
        this.tipDom = ''
        this.tableTitle = '暂无数据'
        this.nowTipData = []
        this.dialogFlag = false
      }, 100)
    },
    reqTableData (data) {
      return new Promise((resolve, reject) => {
        // data.area = [650000]
        // data.enterpriseStatus = ['存续（在营、开业、在业）']
        let str = ''
        for (let key in data) {
          str += ( key + '=' + data[key] + '&')
        }
        this.axios.get('https://1.ink/api/company/businessScope/list?' + str)
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // 弹出表格行被点击
    popupTabelClick (props) {
      this.popupTableItemInfo_select = ''
      this.popupTableItemInfo_show = true
      this.popupTableItemInfo_loading = true
      // typeCount 0 相关企业总数 1 含有专利 2 含有商标
      const { typeCount } = props.row
      let fixedArr = [
        {
          label: '相关企业总数',
          data: { keyword: this.tableTitle, pageSize: 10 }
        },
        {
          label: '含有专利企业数量',
          data: { keyword: this.tableTitle, patent: 1, pageSize: 10 }
        },
        {
          label: '含有商标企业数量',
          data: { keyword: this.tableTitle, brand: 1, pageSize: 10 }
        },
      ]
      this.popupTableItemInfo_reqData = fixedArr[typeCount].data
      this.popupTableItemInfo_tableTitle = fixedArr[typeCount].label
      this.reqTableData(fixedArr[typeCount].data)
        .then(res => {
          this.popupTableItemInfo_loading = false
          if (res.data.status === 0) {
            let data = res.data.result
            for (let i = 0; i < data.industryBucket.length; i++) {
              if (data.industryBucket[i].hierarchy !== 1) {
                data.industryBucket.splice(i, 1)
                i--
              }
            }
            this.popupTableItemInfo_Data = data
          }
        })
    },
    // 弹窗换页
    popopTableItemInfo_changePage (e) {
      console.log(e)
      this.popupTableItemInfo_loading = true
      const reqData = this.popupTableItemInfo_reqData
      reqData.pageNo = e
      this.reqTableData(reqData)
        .then(res => {
          this.popupTableItemInfo_loading = false
          if (res.data.status === 0) {
            if (res.data.status === 0) {
              let data = res.data.result
                for (let i = 0; i < data.industryBucket.length; i++) {
                  if (data.industryBucket[i].hierarchy !== 1) {
                  data.industryBucket.splice(i, 1)
                    i--
                   }
                }
              if (reqData.industryIds) {
                data.industryBucket.unshift({ name: '返回', hierarchy: 1 })
              }
              this.popupTableItemInfo_Data = data
            }
          }
        })
    },
    // 弹窗表格行点击
     popupTabelItemInfo_click (e) {
       const key = e.key
      //  this.$router.push({ path: '/portrait', query: { links: key } })
       const { href } = this.$router.resolve({
          name: `Portrait`,
          query: {
            links: key
          }
        })
       window.open(href, "_blank")
     },
    // 弹窗更多信息企业类型切换
     popupTableItemInfo_selectClass (e) {
       let reqData = this.popupTableItemInfo_reqData
       if (e.name === '全部' || e.name === '返回') {
         this.popupTableItemInfo_select = ''
         delete reqData.industryIds
       } else {
         reqData.industryIds = e.id
       }
      this.popupTableItemInfo_loading = true
      this.reqTableData(reqData)
        .then(res => {
          this.popupTableItemInfo_loading = false
          if (res.data.status === 0) {
            this.popupTableItemInfo_Data = reqData
            if (res.data.status === 0) {
              let data = res.data.result
                for (let i = 0; i < data.industryBucket.length; i++) {
                  if (data.industryBucket[i].hierarchy !== 1) {
                  data.industryBucket.splice(i, 1)
                    i--
                   }
                }
                if (e.name !== '返回') {
                  data.industryBucket.unshift({ name: '返回', hierarchy: 1 })
                }
              this.popupTableItemInfo_Data = data
            }
          }
        })
     },
    jumpToGraph (e) {
      const key = e.key
      // this.$router.push({ path: '/portrait', query: { links: key } })
      const { href } = this.$router.resolve({
        name: `Portrait`,
        query: {
          links: key
        }
      })
      window.open(href, "_blank")
    }
  },
  watch: {
    chainId: {
      handler: function(val) {
        // 获取传入的专利 ID 参数
        console.log("Get chain[id:" + val + "] details.");
        if (val !== undefined) {
          this.$q.loading.show({
            message: "加载创新链中..."
          });
          const _this = this;
          this.axios.get(process.env.VUE_APP_API_BASE_URL + "/chain/detail?id=" + val).then(res => {
              this.$q.loading.hide();
              if (res.data.code === 20000) {
                _this.axios
                  .get("/chaindata/" + val + ".json")
                  .then(res => {
                    _this.chainData = res.data.data;
                  })
                  .catch(() => {
                    console.log("此产业链无导航结构");
                  });
                _this.data = res.data.data.content;
                _this.$nextTick(() => {
                  window.init(res.data.data.source);
                  this.initPopup();
                  window.addEventListener("mainColorCilck", e => {
                    this.chainSearchData.keyword = e.detail.keyWord;
                  });
                });
              } else {
                _this.$q
                  .dialog({
                    title: "提示",
                    message: res.data.msg
                  })
                  .onOk(() => {
                    _this.$router.back();
                  });
              }
            })
            .catch(() => {
              // console.log(111)
            });
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  color: white;
  text-decoration: none;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}
/* 正在点击的链接，鼠标在元素上按下还没有松开*/
a:active {
  text-decoration: none;
}
/* 获得焦点的时候 鼠标松开时显示的颜色*/
a:focus {
  text-decoration: none;
}
.tableInfo_table {
  position: relative;
}
.tableInfo_tip {
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 18px;
  background-color: rgba(229, 229, 229,0.3);
}
.tableInfo_control{
  position: relative;
  padding-right: 5%;
}
.tableInfo_tableTitle {
  font-size: 18px;
}
</style>
