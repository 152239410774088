<template>
  <div>
    <!-- 专利检索模块 -->
    <div v-show="!patentInfo.isShow" class="row">
      <!-- 专利检索框 -->
      <div v-if="isShowSearch" class="col-12">
        <div class="form-search" style="background: #ffffff; border-radius: 4px;">
          <q-form v-show="!fromChain" @submit="onSubmit">
            <q-input
                ref="searchInput"
                v-model="keyword"
                class="q-ma-md"
                dense
                input-style="height: 60px;"
                outlined
                placeholder="请输入您要查询的关键字"
            >
              <template v-slot:prepend>
                <q-icon name="search"/>
              </template>
              <template v-slot:append>
                <q-btn
                    class="full-height"
                    color="slrc-primary"
                    style="margin-right: -12px"
                    unelevated
                    @click="onSubmit"
                >
                  搜索
                </q-btn>
              </template>
            </q-input>
            <div v-if="!isShow" class="result">
              共为你找到<span v-html="list.total"></span>条结果
            </div>
          </q-form>
          <q-card
              v-show="fromChain"
              style="padding:15px 30px"
          >
            <div class="text-h5 text-weight-bold">{{ viewKeyWord }}<span
                class="text-subtitle2 text-italic q-ml-sm">词条解释</span></div>
            <div class="q-mt-sm q-pa-sm text-subtitle1">{{ chainKeyWordIntro ? chainKeyWordIntro : '暂无数据' }}</div>
            <div style="display:flex;margin-top: 10px;align-items: center">
              <div style="font-size: 15px">查询条件：</div>
              <div>
                <q-btn color="primary" size="small" style="margin-right: 15px" unelevated>标题：{{ viewKeyWord }}</q-btn>
                <q-btn color="primary" size="small" style="margin-right: 15px" unelevated>摘要：{{ viewKeyWord }}</q-btn>
                <q-btn v-for="(item, index) in chainItemDescribe.ipc" :key="index" color="primary" size="small"
                       style="margin-right: 15px" unelevated>
                  IPC部：{{ item }}（{{ IPC[item] }}）
                </q-btn>
                <!-- <q-badge label="标题：风电纱" color='priamry' style="padding: 8px 16px;display: flex;align-items: center;justify-content: flex-end"/> -->
              </div>
            </div>
          </q-card>
        </div>
      </div>
      <!-- 帮助指引框 -->
      <div v-if="isShow" class="col-12" style="margin-top: 100px; margin-bottom: 100px;">
        <q-card>
          <q-card-section>
            <q-tabs
                v-model="tab"
                active-color="primary"
                align="left"
                class="text-grey"
                indicator-color="primary"
                style="padding: 24px;"
            >
              <q-tab label="简单搜索教程" name="mails"/>
              <q-tab label="高级检索教程" name="movies"/>
              <q-tab label="表格检索" name="tableSearch"/>
            </q-tabs>
            <q-tab-panels
                v-model="tab"
                animated
                swipeable
                transition-next="jump-up"
                transition-prev="jump-up"
            >
              <q-tab-panel name="mails">
                <div style="display: flex;">
                  <div class="tile" style="margin-right: 24px;">
                    <div class="text-h5">
                      <span class="slrc-tag">入门</span>
                    </div>
                    <p>
                      简单搜索查询简洁方便，仅需输入查询关键信息并敲一下回车键(Enter)，或单击“搜索”按钮即可得到相关资料。
                    </p>
                  </div>
                  <div class="tile" style="margin-right: 24px;">
                    <div class="text-h5">
                      <span class="slrc-tag">特别注意</span>
                    </div>
                    <p>
                      通过申请（专利）号、公开（公告）号查询时，直接输入号码，前面可以不用加
                      ZL 或 CN ，不用加小数点及其后面的数据 。一次搜索多个专利号,
                      可以用OR连接, 如201880022677 OR 201120263542 OR 201180027996
                    </p>
                  </div>
                  <div class="tile">
                    <div class="text-h5">
                      <span class="slrc-tag">分词</span>
                    </div>
                    <p>
                      在一些情况下，系统会对查询关键词进行适当拆分，以防止漏检，比如输入 航空航天发动机 ，会自动转换成 航空航天
                      AND 发动机 来搜索。
                      如不需要进行这种自动拆分，只需在查询词上加英文双引号""，比如输入 "航空航天发动机"，就不会再拆分开了。
                    </p>
                  </div>
                </div>
              </q-tab-panel>
              <q-tab-panel name="movies">
                <div class="tile">
                  <div class="text-h5">检索字段大全</div>
                  <div
                      class="ui bottom attached tab segment active"
                      data-tab="search-field-code-tab"
                  >
                    <div style="display: inline-flex;width: 100%">
                      <strong style="width: 100px;line-height: 30px;">
                        <span class="slrc-tag">关键词</span>
                      </strong>
                      <ul style="width: 90%">
                        <li
                            v-for="item in expression.keyword"
                            :key="item.exp"
                            class="add-to-ss"
                            @click="focus(item.exp)"
                        >
                          {{ item.desc }}
                        </li>
                      </ul>
                    </div>
                    <div class="ui divider"></div>
                    <div style="display: inline-flex;width: 100%">
                      <strong style="width: 100px;line-height: 30px;">
                        <span class="slrc-tag">名称/地址</span>
                      </strong>
                      <ul style="width: 90%">
                        <li
                            v-for="item in expression.nameAndAddress"
                            :key="item.exp"
                            class="add-to-ss"
                            @click="focus(item.exp)"
                        >
                          {{ item.desc }}
                        </li>
                      </ul>
                    </div>
                    <div class="ui divider"></div>
                    <div style="display: inline-flex;width: 100%">
                      <strong style="width: 100px;line-height: 30px;">
                        <span class="slrc-tag">号码</span>
                      </strong>
                      <ul style="width: 90%">
                        <li
                            v-for="item in expression.number"
                            :key="item.exp"
                            class="add-to-ss"
                            @click="focus(item.exp)"
                        >
                          {{ item.desc }}
                        </li>
                      </ul>
                    </div>
                    <div class="ui divider"></div>
                    <div style="display: inline-flex;width: 100%">
                      <strong style="width: 100px;line-height: 30px;">
                        <span class="slrc-tag">日期</span>
                      </strong>
                      <ul style="width: 90%">
                        <li
                            v-for="item in expression.date"
                            :key="item.exp"
                            class="add-to-ss"
                            @click="focus(item.exp)"
                        >
                          {{ item.desc }}
                        </li>
                      </ul>
                    </div>
                    <div class="ui divider"></div>
                    <div style="display: inline-flex;width: 100%">
                      <strong style="width: 100px;line-height: 30px;">
                        <span class="slrc-tag">分类</span>
                      </strong>
                      <ul style="width: 90%">
                        <li
                            v-for="item in expression.category"
                            :key="item.exp"
                            class="add-to-ss"
                            @click="focus(item.exp)"
                        >
                          {{ item.desc }}
                        </li>
                      </ul>
                    </div>
                    <div class="ui divider"></div>
                    <div style="display: inline-flex;width: 100%">
                      <strong style="width: 100px;line-height: 30px;">
                        <span class="slrc-tag">状态</span>
                      </strong>
                      <ul style="width: 90%">
                        <li
                            v-for="item in expression.status"
                            :key="item.exp"
                            class="add-to-ss"
                            @click="focus(item.exp)"
                        >
                          {{ item.desc }}
                        </li>
                      </ul>
                    </div>
                    <div class="ui divider"></div>
                    <div style="display: inline-flex;width: 100%">
                      <strong style="width: 100px;line-height: 30px;">
                        <span class="slrc-tag">其他</span>
                      </strong>
                      <ul style="width: 90%">
                        <li
                            v-for="item in expression.others"
                            :key="item.exp"
                            class="add-to-ss"
                            @click="focus(item.exp)"
                        >
                          {{ item.desc }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="tile">
                  <div class="text-h5">时间范围检索</div>
                  <p>申请日、公开日、授权日可支持时间范围查询 [开始值 TO 结束值]。</p>
                  <p>举例：</p>
                  <p>applicationDate:[2020 TO 2021]，表示查询申请日期在2020年与2021年之间的所有专利。</p>
                </div>

                <div class="tile">
                  <div class="text-h5">复杂逻辑检索</div>
                  <p>支持AND、OR、NOT以及()的逻辑运算，以空格间隔默认为AND关系。</p>
                  <p>举例：</p>
                  <p>applicationYear:[2016 TO 2018] AND
                    电力，表示查询申请日期在2016年与2018年之间的电力相关的所有专利。</p>
                  <p>applicationYear:[2016 TO 2018] AND applicant:"新疆大学" AND
                    电力，表示查询申请日期在2016年与2018年时候，新疆大学申请的电力相关的所有专利。</p>
                  <p>applicationYear:[2016 TO 2018] AND applicant:"新疆大学" AND mainIpc1:"G" AND
                    电力，表示查询申请日期在2016年与2018年之间，新疆大学在主分类号为G上面的电力相关的所有专利。</p>
                  <p>legalStatus:"有效专利" AND province:"乌鲁木齐市" AND
                    电力，表示查询乌鲁木齐市范围内跟电力相关的所有有效专利。</p>
                  <p>inventor:"袁新生" AND inventor:"周明杰" AND
                    电力，表示查询两位发明人之间合作研发的电力相关的所有专利。</p>
                  <p>电力 AND NOT (countryCode:CN AND type:发明公开) AND NOT (countryCode:CN AND type:实用新型) AND NOT
                    legalStatus:失效专利，表示查询电力相关专利但是去掉中国的发明公开和实用新型专利并且不能是失效专利。</p>
                </div>
              </q-tab-panel>
              <q-tab-panel name='tableSearch'>
                <table-search @conditionGenerated='conditionGenerated'/>
              </q-tab-panel>
            </q-tab-panels>
          </q-card-section>
        </q-card>
      </div>
      <!-- <div v-if="!isShow" style="margin-top: 100px;" class="col-12">
        <span>搜索结果:<em v-html="list.total"></em>条, </span>
        <span>耗时:<em v-html="list.took / 1000"></em>秒</span>
      </div> -->
      <!-- 右侧导航框 -->
      <div v-if="!isShow" class="col-3" style="margin-top: 100px;">
        <q-card>
          <q-list bordered class="rounded-borders">
            <q-expansion-item
                caption="Administrative area navigation"
                expand-separator
                icon="explore"
                label="行政区划导航"
                no-connectors
            >
              <q-card>
                <q-card-section>
                  <q-tree
                      :nodes="simple"
                      :selected.sync="selected"
                      :ticked.sync="ticked"
                      class="col-12 col-sm-6"
                      no-connectors
                      node-key="label"
                      tick-strategy="leaf"
                  />
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-list>
          <q-list v-show="!fromChain" bordered class="rounded-borders">
            <q-expansion-item
                caption="IPC classified navigation"
                expand-separator
                icon="category"
                label="IPC 分类导航"
            >
              <q-card>
                <q-card-section>
                  <q-tree
                      :nodes="ipc"
                      no-connectors
                      node-key="id"
                      @lazy-load="test"
                  />
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-list>
          <q-list v-show="fromChain" bordered class="rounded-borders">
            <q-expansion-item
                v-model="chainClass"
                caption="classified navigation"
                expand-separator
                icon="category"
                label="产业链分类导航"
                no-connectors
            >
              <q-card>
                <q-card-section>
                  <q-tree
                      :expanded.sync="chainClassExpanded"
                      :nodes="chainData"
                      :selected.sync="chainClassSelect"
                      class="col-12 col-sm-6"
                      label-key="name"
                      no-connectors
                      node-key="id"
                      selected-color="primary"
                  />
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-list>
          <q-list v-show="fromChain" bordered class="rounded-borders">
            <q-expansion-item
                v-model="resultFilter"
                caption="classified navigation"
                expand-separator
                icon="category"
                label="筛选查询条件"
                no-connectors
            >
              <q-card>
                <q-card-section>
                  <q-tree
                      :expanded.sync="filterExpanded"
                      :nodes="filterList"
                      :selected.sync="filterSelect"
                      class="col-12 col-sm-6"
                      label-key="name"
                      no-connectors
                      node-key="id"
                      selected-color="primary"
                  />
                </q-card-section>
              </q-card>
            </q-expansion-item>
          </q-list>
        </q-card>
        <q-slide-transition v-show="fromChain">
          <q-card v-show="(filterSelect === 1 || filterSelect === 2) && filterExpanded.length !== 0 && resultFilter"
                  class="q-mt-md q-pa-md">
            <q-input v-model="chainSearchStartY" label="起始年份" standout="bg-teal text-white" type="number"/>
            <q-input v-model="chainSearchEndY" class="q-mt-sm" label="截止年份" standout="bg-teal text-white"
                     type="number"/>
            <q-btn class="q-mt-sm" color="secondary" label="查询" style="width:100%;text-align:center"
                   @click="chainSearch('year')"/>
          </q-card>
        </q-slide-transition>
      </div>
      <!-- 专利列表框 -->
      <div v-if="!isShow" class="col-9" style="margin: 100px 0;">
        <q-card>
          <q-banner v-show="fromChain && list.patents.length === 0">不选择任何条件可查询当前关键字全部内容</q-banner>
          <q-list v-for="(item, index) in list.patents" :key="item.id">
            <q-item clickable @click="getPatent(item.id)">
              <!-- 第一行 -->
              <div class="row justify-start">
                <!-- 第一列 -->
                <div class="col-9">
                  <div class="row q-mb-sm">
                    <q-item-label
                    >{{ (list.page - 1) * 10 + index + 1 }}.
                    </q-item-label
                    >
                    <q-badge
                        :color="setTypeColor(item.type)"
                        :label="item.type"
                        caption
                        class="q-mr-sm"
                    ></q-badge>
                    <q-item-label
                        class="q-mr-sm"
                        color="blue"
                        v-html="item.title"
                    ></q-item-label>
                    <q-badge
                        :color="setStatusColor(item.legalStatus)"
                        :label="item.legalStatus"
                        caption
                        outline
                    ></q-badge>
                  </div>
                  <div class="row">
                    <q-item-label caption>公开(公告)号：</q-item-label>
                    <span v-html="item.documentNumber"></span>
                  </div>
                  <div class="row">
                    <q-item-label caption>公开(公告)日：</q-item-label>
                    <span caption v-html="item.documentDate"></span>
                  </div>
                  <div class="row">
                    <q-item-label caption>申请号：</q-item-label>
                    <span caption v-html="item.applicationNumber"></span>
                  </div>
                  <div class="row">
                    <q-item-label caption>申请日：</q-item-label>
                    <span caption v-html="item.applicationDate"></span>
                  </div>
                  <div class="row">
                    <q-item-label caption>申请人：</q-item-label>
                    <span caption v-html="item.applicant"></span>
                  </div>
                  <div class="row">
                    <q-item-label caption>发明人：</q-item-label>
                    <span caption v-html="item.inventor"></span>
                  </div>
                  <div class="row">
                    <q-item-label caption>IPC分类号：</q-item-label>
                    <span caption v-html="item.ipc"></span>
                  </div>
                </div>
                <!-- 第二列 -->
                <div class="col-3">
                  <q-img
                      :src="getImageURL(item.imagePath)"
                      style="height: 140px; max-width: 150px"
                  />
                </div>
                <div class="row">
                  <q-item-label caption>摘要：</q-item-label>
                  <div
                      caption
                      v-html="item.summary ? item.summary : '暂无'"
                  ></div>
                </div>
              </div>
            </q-item>
            <q-separator inset spaced/>
          </q-list>
          <q-pagination
              v-model="current"
              :boundary-numbers="false"
              :direction-links="true"
              :max="max"
              :max-pages="10"
              class="q-pa-lg flex flex-center"
              color="purple"
          >
          </q-pagination>
        </q-card>
      </div>
    </div>
    <!-- 专利信息模块 -->
    <div v-if="patentInfo.isShow" style="margin-bottom: 100px;">
      <patent :patentId="patentInfo.patentId" @backList='backList'/>
    </div>
  </div>
</template>

<script>
import Patent from './Patent.vue';
import TableSearch from '../components/TableSearch.vue';
import {getImageURL} from "@/utils/util";
// import FieldExplain from '../components/FieldExplain.vue'
// @ is an alias to /src
// import Footer from "@/components/Footer.vue";

const expression = {
  keyword: [
    {
      exp: "ti:",
      desc: "ti:标题"
    },
    {
      exp: "eti:",
      desc: "eti:英文标题"
    },
    {
      exp: "cti:",
      desc: "cti:中文标题"
    },
    {
      exp: "ab:",
      desc: "ab:摘要"
    },
    {
      exp: "eab:",
      desc: "eab:英文摘要"
    },
    {
      exp: "cab:",
      desc: "cab:中文摘要"
    },
    {
      exp: "cl:",
      desc: "cl:权利要求"
    },
    {
      exp: "ecl:",
      desc: "ecl:英文权利要求"
    },
    {
      exp: "d:",
      desc: "d:说明书"
    },
    {
      exp: "eds:",
      desc: "eds:英文说明书"
    },
    {
      exp: "ceti:",
      desc: "ceti:标题中文和英文"
    },
    {
      exp: "cets:",
      desc: "cets:标题与摘要的中英文"
    },
    {
      exp: "cetsc:",
      desc: "cetsc:标题、摘要、权利要求的中英文"
    }
  ],
  nameAndAddress: [
    {
      exp: "ap:",
      desc: "ap:申请人"
    },
    {
      exp: "fap:",
      desc: "fap:第一申请人"
    },
    {
      exp: "addr:",
      desc: "addr:申请人地址"
    },
    {
      exp: "aee:",
      desc: "aee:专利权人"
    },
    {
      exp: "caee:",
      desc: "caee:当前专利权人"
    },
    {
      exp: "in:",
      desc: "in:发明人"
    },
    {
      exp: "ag:",
      desc: "ag:代理机构"
    },
    {
      exp: "at:",
      desc: "at:代理人"
    }
  ],
  number: [
    {
      exp: "n:",
      desc: "n:号码"
    },
    {
      exp: "dn:",
      desc: "dn:公开（公告）号"
    },
    {
      exp: "an:",
      desc: "an:申请号"
    },
    {
      exp: "pr:",
      desc: "pr:优先权号"
    }
  ],
  date: [
    {
      exp: "dd:",
      desc: "dd:公开（公告）日期"
    },
    {
      exp: "ddy:",
      desc: "ddy:公开（公告）日期年"
    },
    {
      exp: "ddm:",
      desc: "ddm:公开(公告)日期月"
    },
    {
      exp: "ad:",
      desc: "ad:申请日期"
    },
    {
      exp: "ady:",
      desc: "ady:申请日期年"
    },
    {
      exp: "adm:",
      desc: "adm:申请日期月"
    },
    {
      exp: "pctDate:",
      desc: "pctDate:PCT进入国家阶段日"
    }
  ],
  category: [
    {
      exp: "ipc:",
      desc: "ipc:国际IPC分类"
    },
    {
      exp: "ipc-section:",
      desc: "ipc-section:IPC分类-部"
    },
    {
      exp: "ipc-class:",
      desc: "ipc-class:IPC分类-大类"
    },
    {
      exp: "ipc-subclass:",
      desc: "ipc-subclass:IPC分类-小类"
    },
    {
      exp: "ipc-group:",
      desc: "ipc-group:IPC分类-大组"
    },
    {
      exp: "ipc-subgroup:",
      desc: "ipc-subgroup:IPC分类-小组"
    },
    {
      exp: "ipc-main:",
      desc: "ipc-main:IPC主分类"
    },
    {
      exp: "ipcm-section:",
      desc: "ipcm-section:IPC主分类-部"
    },
    {
      exp: "ipcm-class:",
      desc: "ipcm-class:IPC主分类-大类"
    },
    {
      exp: "ipcm-subclass:",
      desc: "ipcm-subclass:IPC主分类-小类"
    },
    {
      exp: "ipcm-group:",
      desc: "ipcm-group:IPC主分类-大组"
    },
    {
      exp: "ipcm-subgroup:",
      desc: "ipcm-subgroup:IPC主分类-小组"
    },
    {
      exp: "uspc:",
      desc: "uspc:USPC分类号"
    },
    {
      exp: "uspcc:",
      desc: "uspcc:USPC大类"
    },
    {
      exp: "fi:",
      desc: "fi:日本FI分类"
    },
    {
      exp: "ft:",
      desc: "ft:日本F-term分类"
    },
    {
      exp: "loc:",
      desc: "loc:洛迦诺分类"
    },
    {
      exp: "cpc:",
      desc: "cpc:CPC联合专利分类"
    }
  ],
  status: [
    {
      exp: "ls:",
      desc: "ls:专利有效性"
    },
    {
      exp: "currentStatus:",
      desc: "currentStatus:当前法律状态"
    }
  ],
  others: [
    {
      exp: "type:",
      desc: "type:专利类型"
    },
    {
      exp: "cc:",
      desc: "cc:国家代码"
    },
    {
      exp: "acc:",
      desc: "acc:申请人国家代码"
    },
    {
      exp: "kc:",
      desc: "kc:文献类型"
    },
    {
      exp: "lang:",
      desc: "lang:语言"
    },
    {
      exp: "apt:",
      desc: "apt:申请人类型"
    },
    {
      exp: "ap-zip:",
      desc: "ap-zip:申请人邮编"
    },
    {
      exp: "country:",
      desc: "country:国家"
    },
    {
      exp: "province:",
      desc: "province:省份"
    },
    {
      exp: "city:",
      desc: "city:城市"
    },
    {
      exp: "aeet:",
      desc: "aeet:专利权人类型"
    },
    {
      exp: "caeet:",
      desc: "caeet:当前专利权人类型"
    },
    {
      exp: "agc:",
      desc: "agc:代理机构代码"
    },
    {
      exp: "legalTag:",
      desc: "legalTag:法律标签"
    },
    {
      exp: "legalEvent:",
      desc: "legalEvent:法律事件(转让，质押)"
    },
    {
      exp: "maintainYears:",
      desc: "maintainYears:维持年份"
    },
    {
      exp: "citedCount:",
      desc: "citedCount:引证数"
    },
    {
      exp: "citingCount:",
      desc: "citingCount:被引证数"
    },
    {
      exp: "level:",
      desc: "level:评级"
    },
    {
      exp: "judgment.date:",
      desc: "judgment.date:裁判文书日期"
    },
    {
      exp: "judgment.title:",
      desc: "judgment.title:裁判文标题"
    },
    {
      exp: "judgment.caseId:",
      desc: "judgment.caseId:裁判文书案号"
    },
    {
      exp: "judgment.court:",
      desc: "judgment.court:裁判文书法院"
    },
    {
      exp: "judgment.province:",
      desc: "judgment.province:裁判文书省"
    },
    {
      exp: "judgment.city:",
      desc: "judgment.city:裁判文书市"
    },
    {
      exp: "judgment.accuser:",
      desc: "judgment.accuser:裁判文书原告"
    },
    {
      exp: "judgment.defendant:",
      desc: "judgment.defendant:裁判文书被告"
    }
  ]
};

export default {
  components: {Patent, TableSearch},
  name: "Search",
  props: {
    fromChain: {
      typeof: Boolean,
      default: false
    },
    chainSearchData: {
      typeof: Object,
      default: () => {
        return {
          keyword: '暂无数据',
          ipc: ''
        }
      }
    },
    chainData: {
      typeof: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      isShow: true,
      isShowSearch: true,
      expression,
      list: {
        code: 0,
        success: null,
        took: 0,
        total: 0,
        patents: [],
        // 当前页面
        page: 1,
        // 下一页
        nextPage: 2,
        // 总页数
        totalPages: 10
      },
      current: 1,
      max: "",
      name: null,
      age: null,
      keyword: "",
      tab: "mails",
      splitterModel: 20,
      simple: [
        {
          label: "新疆维吾尔自治区",
          plabel: "",
          noTick: true,
          handler: node => this.handleClick(node),
          children: [
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "乌鲁木齐市"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "克拉玛依市"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "吐鲁番市"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "哈密市"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "塔城地区"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "阿勒泰地区"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "阿克苏地区"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "喀什地区"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "和田地区"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "昌吉回族自治州"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "博尔塔拉蒙古自治州"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "巴音郭楞蒙古自治州"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "克孜勒苏柯尔克孜自治州"
            },
            {
              plabel: "新疆维吾尔自治区",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "伊犁哈萨克自治州"
            }
          ]
        },
        {
          label: "新疆生产建设兵团",
          plabel: "",
          noTick: true,
          handler: node => this.handleClick(node),
          children: [
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "一师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "二师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "三师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "四师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "五师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "六师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "七师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "八师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "九师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "十师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "十一师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "十二师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "十三师"
            },
            {
              plabel: "新疆生产建设兵团",
              handler: node => this.handleClick(node),
              noTick: true,
              label: "十四师"
            }
          ]
        }
      ],
      ipc: [],
      selected: "",
      ticked: [],
      chainClassSelect: "",
      chainClassExpanded: [],
      chainClass: false,
      chainKeyWordIntro: "",
      chainItemDescribe: {},
      // 条件筛选相关
      resultFilter: true,
      filterList: [
        {
          name: "年份筛选",
          id: 0,
          children: [
            {
              name: "公开年筛选",
              id: 1
            },
            {
              name: "申请年筛选",
              id: 2
            }
          ]
        }
      ],
      filterExpanded: [0],
      viewKeyWord: "",
      filterSelect: 1,
      chainSearchStartY: "",
      chainSearchEndY: "",
      IPC: {
        A: '人类生活必需',
        B: '作业；运输',
        C: '化学；冶金',
        D: '纺织；造纸',
        E: '固定建筑物',
        F: '机械工程；照明；加热；武器；爆破',
        G: '物理',
        H: '电学'
      },
      // 专利详情
      patentInfo: {
        isShow: false,
        patentId: ''
      }
    };
  },
  created() {
    this.getIpcTree(0);
  },
  mounted() {
    this.getUrlParam("links");
  },
  watch: {
    current(page) {
      this.getPatentsList(page);
    },
    //route  keyword changed
    '$route.query.keywords':{
      handler: function (val) {
        if (val) {
          this.keyword = val
          this.onSubmit()
        }
      },
      immediate: true
    },
    chainSearchData: {
      handler: function (val) {
        if (val.keyword !== "暂无数据" && val) {
          let ipc = ''
          if (this.chainData.length) {
            let data = this.getChainNodeInfo(this.chainData, val.keyword);
            this.chainItemDescribe = data
            ipc = this.formatChainIpc(data.ipc)
            if (!data.search_disabled) {
              this.chainKeyWordIntro = data.intro;
              this.chainClassSelect = data.id;
              this.chainClassExpanded = data.pids;
              this.chainClass = true;
            }
          }
          this.viewKeyWord = val.keyword
          this.keyword = `(ti:${val.keyword} ${ipc}) OR (ab:${val.keyword} ${ipc})`
          this.onSubmit();
        }
      },
      immediate: true,
      deep: true
    },
    chainClassSelect(val) {
      if (val) {
        let data = this.getChainNodeInfo(this.chainData, val);
        this.chainItemDescribe = data
        if (!data.search_disabled) {
          let ipc = this.formatChainIpc(data.ipc)
          this.chainKeyWordIntro = data.intro;
          this.keyword = `(ti:${data.name} ${ipc}) OR (ab:${data.name} ${ipc})`
          this.viewKeyWord = data.name
          this.onSubmit();
        }
      }
    }
  },
  methods: {
    getImageURL,
    // 格式化一下从产业链获取IPC数据格式
    formatChainIpc(ipc) {
      if (ipc) {
        if (typeof ipc === 'string') {
          return 'ipc-section:' + ipc
        } else if (ipc.constructor === Array) {
          let str = ''
          for (let i = 0; i < ipc.length; i++) {
            if (i != ipc.length - 1) {
              str += 'ipc-section:' + ipc[i] + ' OR '
            } else {
              str += 'ipc-section:' + ipc[i]
            }
          }
          return str
        }
      } else {
        return ''
      }
    },
    onSubmit() {
      this.patentInfo.isShow = false
      this.patentInfo.patentId = ''

      this.$q.loading.show();
      const {keyword} = this;
      if (keyword) {
        this.getPatentsList(1);
      } else {
        this.$q
            .dialog({
              title: "提示",
              message: "请输入检索内容"
            })
            .onOk(() => {
            })
            .onCancel(() => {
            })
            .onDismiss(() => {
            });
        this.$q.loading.hide();
      }
    },
    getPatentsList(page) {
      this.$q.loading.show();
      let keyword = this.keyword
      if (keyword.indexOf('新疆生产建设兵团') !== -1) {
        console.log(keyword)
        if (keyword === 'province:新疆生产建设兵团') {
          keyword = 'province:新疆维吾尔自治区 AND (addr:一师 OR addr:二师 OR addr:三师 OR addr:四师 OR addr:五师 OR addr:六师 OR addr:七师 OR addr:八师 OR addr: 九师 OR addr:十师 OR addr:十一师 OR addr:十二师 OR addr:十三师 OR addr:十四师)'
        } else {
          keyword = 'province: 新疆维吾尔自治区 AND addr:' + keyword.slice(13)
        }
      }
      this.$http
          .get(
              process.env.VUE_APP_API_BASE_URL + "/proxier/patent/search?ds=cn&q=" +
              keyword +
              "&p=" +
              page +
              "&ps=10&s=relation&hl=1"
          )
          .then(response => {
            this.$q.loading.hide();
            if (response.data.code === 207) {
              this.$q
                  .dialog({
                    title: "提示",
                    message: "系统维护中，请稍后再试。"
                  })
                  .onOk(() => {
                  })
                  .onCancel(() => {
                  })
                  .onDismiss(() => {
                  });
            } else {
              this.list = response.data;
              this.current = response.data.page;
              this.max = response.data.totalPages;
              if (this.list.patents.length === 0) {
                if (!this.fromChain) {
                  this.isShow = true;
                } else {
                  this.isShow = false;
                }
                this.$q
                    .dialog({
                      title: "提示",
                      message: "未查询到相关专利信息, 建议重新输入关键字"
                    })
                    .onOk(() => {
                    })
                    .onCancel(() => {
                    })
                    .onDismiss(() => {
                    });
              } else {
                this.isShow = false;
                this.$nextTick(() => {
                  this.$emit('chainSearchFinish')
                })
              }
            }
            this.$q.loading.hide();
          });
    },
    setTypeColor(v) {
      if (v.indexOf("实用新型") != -1) {
        return "blue";
      } else if (v.indexOf("发明公开") != -1) {
        return "purple";
      } else if (v.indexOf("发明授权") != -1) {
        return "green";
      }
    },
    setStatusColor(v) {
      if (v.indexOf("失效") != -1 || v.indexOf("无效") != -1) {
        return "grey";
      } else {
        return "secondary";
      }
    },
    getPatent(id) {
      //this.patentInfo.isShow = true
      //this.patentInfo.patentId = id
      let target = this.$router.resolve({name: "Patent", params: {id: id}});
      window.open(target.href, "_blank");
    },
    // 从专利信息返回专利列表
    backList() {
      this.patentInfo.isShow = false
      this.patentInfo.parentId = ''
    },
    test({node, done}) {
      // 获取IPC二级列表
      const params = {
        parentId: node.id
      };
      this.$http
          .get(process.env.VUE_APP_API_BASE_URL + "/ipc/parent", {params})
          .then(response => {
            if (response.status === 200) {
              response.data.result.forEach(item => {
                item.label = item.mark + " " + item.name;
                item.lazy = true;
                item.noTick = true;
                item.handler = node => this.handleClickOfIPC(node);
              });
            }
            setTimeout(() => {
              done(response.data.result);
            }, 1000);
          });
    },
    getIpcTree(args) {
      const query = args;
      if (query === 0) {
        // 获取IPC一级列表
        const params = {
          parentId: query
        };
        this.$http
            .get(process.env.VUE_APP_API_BASE_URL + "/ipc/parent", {params})
            .then(response => {
              if (response.status === 200) {
                if (response.data) {
                  response.data.result.forEach(item => {
                    item.label = item.mark + " " + item.name;
                    item.noTick = true;
                    item.lazy = true;
                    item.handler = node => this.handleClickOfIPC(node);
                  });
                  this.ipc = response.data.result;
                }
              }
            });
      }
    },
    handleClick(node) {
      // console.log(node)
      // 这里如果只有省份 不能用city
      if (!node.plabel) {
        this.keyword = "province:" + node.plabel + node.label;
      } else {
        this.keyword = "city:" + node.plabel + node.label;
      }
      if (this.fromChain) {
        this.chainSearchStartY = ''
        this.chainSearchEndY = ''
        this.keyword += ' ti:' + this.chainSearchData.keyword
      }
      this.getPatentsList(1);
    },
    handleClickOfIPC(node) {
      this.keyword = "ipc:" + node.mark;
      this.getPatentsList(1);
    },
    getUrlParam(args) {
      // 构造一个含有目标参数的正则表达式对象
      var reg = new RegExp("(^|&)" + args + "=([^&]*)(&|$)");
      // 匹配目标参数
      var r = window.location.hash.substr(9).match(reg);
      if (r != null) {
        this.$q.loading.show();
        this.keyword = decodeURI(r[2]);
        this.getPatentsList(1);
        this.isShowSearch = false;
      }
    },
    focus(otherKeyWord) {
      const {$refs} = this;
      $refs.searchInput.focus();
      if (this.keyword) {
        this.keyword = this.keyword + " " + otherKeyWord;
      } else {
        this.keyword = this.keyword + otherKeyWord;
      }
    },
    // 递归寻找目标数据(词条解释方面的应用)
    getChainNodeInfo(list, value) {
      let data = {
        name: '',
        id: "",
        pids: [],
        intro: '暂无数据',
        ipc: '',
        search_disabled: false
      }
      if (typeof value === 'number') {
        for (let i = 0; i < list.length; i++) {
          if (list[i].id === value) {
            data.id = list[i].id
            data.name = list[i].name
            data.pids = list[i].pids
            data.intro = list[i].intro
            data.ipc = list[i].ipc
            data.search_disabled = list[i].search_disabled
            return data
          } else {
            if (this.getChainNodeInfo(list[i].children, value)) {
              return this.getChainNodeInfo(list[i].children, value)
            }
          }
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          if (list[i].name === value) {
            data.id = list[i].id
            data.name = list[i].name
            data.intro = list[i].intro
            data.pids = list[i].pids
            data.ipc = list[i].ipc
            data.search_disabled = list[i].search_disabled
            return data
          } else {
            if (this.getChainNodeInfo(list[i].children, value)) {
              return this.getChainNodeInfo(list[i].children, value)
            }
          }
        }
      }
    },
    chainSearch(e) {
      if (e === 'year') {
        let start = this.chainSearchStartY
        let end = this.chainSearchEndY
        let flag = this.filterSelect === 1 ? ' ddy:' : ' ady:'
        if (!start && !end) {
          this.keyword = this.viewKeyWord
          this.onSubmit()
        } else if ((!start && end) || (!end && start)) {
          this.keyword += flag + start + end
          // console.log(this.keyword)
          this.onSubmit()
        } else if (start && end) {
          let condition = `[${start} TO ${end}]`
          this.keyword += flag + condition
          this.onSubmit()
          // console.log(this.keyword)
        }
      }
    },
    // 表格检索条件ok
    conditionGenerated(e) {
      console.log(e);
      this.keyword = e;
    }
  }
};


</script>

<style scoped>
label.q-ma-md {
  margin: 0px;
}

.add-to-ss {
  color: #4183c4;
  cursor: pointer;
  float: left;
  width: 250px;
  line-height: 30px;
  list-style: none;
}

.divider:not(.vertical):not(.horizontal) {
  /* border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
  margin-bottom: 32px;
}

.q-tab-panel {
  padding: 20px;
}

.q-card__section--vert {
  padding: 20px;
}

.tile {
  padding: 24px;
  background: #FBFBFC;
  margin-bottom: 24px;
  border-radius: 4px;
}

.tile > .text-h5 {
  margin-bottom: 24px;
  font-size: 16px;
  color: #333333;
  font-weight: 500;
}

.slrc-tag {
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.88);
}

.form-search {
  position: relative;
}

.result {
  top: 80px;
  position: absolute;
  font-size: 14px;
  color: #fff;
}

.result span {
  font-size: 16px;
  font-weight: bold;
  padding: 0 4px;

}
</style>
