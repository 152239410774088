import { render, staticRenderFns } from "./ChainDetail.vue?vue&type=template&id=0d81f9da&scoped=true"
import script from "./ChainDetail.vue?vue&type=script&lang=js"
export * from "./ChainDetail.vue?vue&type=script&lang=js"
import style0 from "./ChainDetail.vue?vue&type=style&index=0&id=0d81f9da&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d81f9da",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QCard,QBreadcrumbs,QBreadcrumbsEl,QPopupProxy,QTable,QTr,QTd,QInnerLoading,QSpinnerGears,QDialog,QSelect,QItem,QItemSection,QPagination});
