<template>
  <div class="out">
    <!-- 关键字 -->
    <div class="fieldItem">
      <div style="min-width: 8%" class="tip">关键字</div>
      <div class="select" style="min-width: 15%">
        <q-select clearable dense label-slot v-model="valueObj.keyword.type" :options="fieldsTable.keyword" option-label='desc' option-value='exp'>
          <div slot="label" style="margin-bottom: 30px">请选择关键字相关筛选条件</div>
        </q-select>
      </div>
      <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 50%;display: flex;align-items: flex-end">
          <q-input v-model="valueObj.keyword.value" :placeholder="valueObj.keyword.type ? (valueObj.keyword.type.placeholder || '请输入相关内容') : '请输入相关内容'" style="width: 100%" dense />
          <div style='width: 10px'></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click='addItem("keyword")'>add</span>
      </div>
    </div>
    <!-- 关键字的子项 -->
    <div class="fieldItemChildren">
      <div class="child" v-for="(item, index) in valueObj.keyword.children" :key="index">
        <div style="min-width: 8%"></div>
        <div style="width: 15%;display: flex">
          <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 30%" v-model="item.logic" ></q-select>
          <div style="width: 5%"></div>
          <!-- <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 65%" v-model="item.logic" ></q-select> -->
          <q-select dense label-slot v-model="item.type" :options="fieldsTable.keyword" style="width: 65%" option-label='desc' option-value='exp'>
            <div slot="label" style="margin-bottom: 30px">请选择相关筛选条件</div>
          </q-select>
        </div>
        <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 30%;display: flex;align-items: flex-end">
          <q-input v-model="item.value" :placeholder="item.type ? (item.type.placeholder || '请输入相关内容') : '请输入相关内容'" style="width: 100%" dense />
          <div style='width: 10px'></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="remove('keyword', index)">remove</span>
      </div>
      </div>
    </div>
    <!-- 分类号 -->
    <div style="height: 20px"></div>
    <div class="fieldItem">
      <div style="min-width: 8%" class="tip">分类号</div>
      <div class="select" style="min-width: 15%">
        <q-select clearable dense label-slot v-model="valueObj.category.type" :options="fieldsTable.category" option-label='desc' option-value='exp'>
          <div slot="label" style="margin-bottom: 30px">请选择分类相关筛选条件</div>
        </q-select>
      </div>
      <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 50%;display: flex;align-items: flex-end">
          <q-input v-model="valueObj.category.value" style="width: 100%" :placeholder="valueObj.category.type ? (valueObj.category.type.placeholder || '请输入相关内容') : '请输入相关内容'" dense />
          <!-- <q-icon name="warning" /> -->
          <div style='width: 10px'></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="addItem('category')">add</span>
      </div>
    </div>
    <!-- 分类号的子项 -->
    <div class="fieldItemChildren">
      <div class="child" v-for="(item, index) in valueObj.category.children" :key="index">
        <div style="min-width: 8%"></div>
        <div style="width: 15%;display: flex">
          <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 30%" v-model="item.logic" ></q-select>
          <div style="width: 5%"></div>
          <!-- <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 65%" v-model="item.logic" ></q-select> -->
          <q-select dense label-slot v-model="item.type" :options="fieldsTable.category" style="width: 65%" option-label='desc' option-value='exp'>
            <div slot="label" style="margin-bottom: 30px">请选择相关筛选条件</div>
          </q-select>
        </div>
        <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 30%;display: flex;align-items: flex-end">
          <q-input v-model="item.value" :placeholder="item.type ? (item.type.placeholder || '请输入相关内容') : '请输入相关内容'" style="width: 100%" dense />
          <div style='width: 10px'></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="remove('category', index)">remove</span>
      </div>
      </div>
    </div>
    <!-- 名称与地址 -->
    <div style="height: 20px"></div>
    <div class="fieldItem">
      <div style="min-width: 8%" class="tip">名称与地址</div>
      <div class="select" style="min-width: 15%">
        <q-select clearable dense label-slot v-model="valueObj.nameAndAddress.type" :options="fieldsTable.nameAndAddress" option-label='desc' option-value='exp'>
          <div slot="label" style="margin-bottom: 30px">请选择名称与地址相关筛选条件</div>
        </q-select>
      </div>
      <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 50%;display: flex;align-items: flex-end">
          <q-input v-model="valueObj.nameAndAddress.value" style="width: 100%" :placeholder="valueObj.nameAndAddress.type ? (valueObj.nameAndAddress.type.placeholder || '请输入相关内容') : '请输入相关内容'" dense />
          <div style='width: 10px'></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="addItem('nameAndAddress')">add</span>
      </div>
    </div>
    <!-- 名称与地址子项 -->
    <div class="fieldItemChildren">
      <div class="child" v-for="(item, index) in valueObj.nameAndAddress.children" :key="index">
        <div style="min-width: 8%"></div>
        <div style="width: 15%;display: flex">
          <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 30%" v-model="item.logic" ></q-select>
          <div style="width: 5%"></div>
          <!-- <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 65%" v-model="item.logic" ></q-select> -->
          <q-select dense label-slot v-model="item.type" :options="fieldsTable.nameAndAddress" style="width: 65%" option-label='desc' option-value='exp'>
            <div slot="label" style="margin-bottom: 30px">请选择相关筛选条件</div>
          </q-select>
        </div>
        <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 30%;display: flex;align-items: flex-end">
          <q-input v-model="item.value" :placeholder="item.type ? (item.type.placeholder || '请输入相关内容') : '请输入相关内容'" style="width: 100%" dense />
          <div style='width: 10px'></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="remove('nameAndAddress', index)">remove</span>
      </div>
      </div>
    </div>
    <!-- 号码 -->
    <div style="height: 20px"></div>
    <div class="fieldItem">
      <div style="min-width: 8%" class="tip">号码</div>
      <div class="select" style="min-width: 15%">
        <q-select clearable dense label-slot v-model="valueObj.number.type" :options="fieldsTable.number" option-label='desc' option-value='exp'>
          <div slot="label" style="margin-bottom: 30px">请选择号码相关筛选条件</div>
        </q-select>
      </div>
      <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 50%;display: flex;align-items: flex-end">
          <q-input v-model="valueObj.number.value" style="width: 100%" :placeholder="valueObj.number.type ? (valueObj.number.type.placeholder || '请输入相关内容') : '请输入相关内容'" dense />
          <div style='width: 10px'></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="addItem('number')">add</span>
      </div>
    </div>
    <!-- 号码子项 -->
    <div class="fieldItemChildren">
      <div class="child" v-for="(item, index) in valueObj.number.children" :key="index">
        <div style="min-width: 8%"></div>
        <div style="width: 15%;display: flex">
          <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 30%" v-model="item.logic" ></q-select>
          <div style="width: 5%"></div>
          <!-- <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 65%" v-model="item.logic" ></q-select> -->
          <q-select dense label-slot v-model="item.type" :options="fieldsTable.number" style="width: 65%" option-label='desc' option-value='exp'>
            <div slot="label" style="margin-bottom: 30px">请选择相关筛选条件</div>
          </q-select>
        </div>
        <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 30%;display: flex;align-items: flex-end">
          <q-input v-model="item.value" :placeholder="item.type ? (item.type.placeholder || '请输入相关内容') : '请输入相关内容'" style="width: 100%" dense />
          <div style='width: 10px'></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="remove('number', index)">remove</span>
      </div>
      </div>
    </div>
    <!-- 日期 -->
    <div style="height: 20px"></div>
    <div class="fieldItem">
      <div style="min-width: 8%" class="tip">日期</div>
      <div class="select" style="min-width: 15%">
        <q-select clearable dense label-slot v-model="valueObj.date.type" :options="fieldsTable.date" option-label='desc' option-value='exp'>
          <div slot="label" style="margin-bottom: 30px">请选择日期相关筛选条件</div>
        </q-select>
      </div>
      <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 50%;display: flex;align-items: flex-end">
        <!-- <q-date @range-end='valueObj.date.pickerShow = false' mask='YYYY-MM-DD' v-model="valueObj.date.value" range /> -->
          <q-input maxlength="0" :value="valueObj.date.value ? valueObj.date.value.from + ' 至 ' + valueObj.date.value.to : ''" style="width: 100%" @click="valueObj.date.pickerShow = true" :placeholder="valueObj.date.type ? (valueObj.date.type.placeholder || '请输入相关内容') : '请输入相关内容'" dense>
            <q-popup-proxy v-model="valueObj.date.pickerShow" target=".flag" :breakpoint="600">
              <q-date @range-end='valueObj.date.pickerShow = false' mask='YYYY-MM-DD' v-model="valueObj.date.value" range />
            </q-popup-proxy>
          </q-input>
          <div style='width: 10px' class="flag"></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="addItem('date')">add</span>
      </div>
    </div>
    <!-- 日期子项 -->
    <div class="fieldItemChildren">
      <div class="child" v-for="(item, index) in valueObj.date.children" :key="index">
        <div style="min-width: 8%"></div>
        <div style="width: 15%;display: flex">
          <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 30%" v-model="item.logic" ></q-select>
          <div style="width: 5%"></div>
          <!-- <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 65%" v-model="item.logic" ></q-select> -->
          <q-select dense label-slot v-model="item.type" :options="fieldsTable.date" style="width: 65%" option-label='desc' option-value='exp'>
            <div slot="label" style="margin-bottom: 30px">请选择相关筛选条件</div>
          </q-select>
        </div>
        <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 30%;display: flex;align-items: flex-end">
          <q-input axlength="0" :value="item.value ? item.value.from + ' 至 ' + item.value.to : ''" style="width: 100%" @click="item.pickerShow = true" :placeholder="item.type ? (item.type.placeholder || '请输入相关内容') : '请输入相关内容'" dense>
            <q-popup-proxy v-model="item.pickerShow" :target="'.flag' + index" :breakpoint="600">
              <q-date @range-end='item.pickerShow = false' mask='YYYY-MM-DD' v-model="item.value" range />
            </q-popup-proxy>
          </q-input>
          <div style='width: 10px' :class="'flag' + index"></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="remove('date', index)">remove</span>
      </div>
      </div>
    </div>
    <!-- 其他筛选 -->
    <div style="height: 20px"></div>
    <div class="fieldItem">
      <div style="min-width: 8%" class="tip">其他</div>
      <div class="select" style="min-width: 15%">
        <q-select clearable dense label-slot v-model="valueObj.others.type" :options="fieldsTable.others" option-label='desc' option-value='exp'>
          <div slot="label" style="margin-bottom: 30px">请选择筛选条件</div>
        </q-select>
      </div>
      <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 50%;display: flex;align-items: flex-end">
          <q-input v-model="valueObj.others.value" style="width: 100%" :placeholder="valueObj.others.type ? (valueObj.others.type.placeholder || '请输入相关内容') : '请输入相关内容'" dense />
          <div style='width: 10px'></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="addItem('others')">add</span>
      </div>
    </div>
    <!-- 其他筛选子项 -->
    <div class="fieldItemChildren">
      <div class="child" v-for="(item, index) in valueObj.others.children" :key="index">
        <div style="min-width: 8%"></div>
        <div style="width: 15%;display: flex">
          <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 30%" v-model="item.logic" ></q-select>
          <div style="width: 5%"></div>
          <!-- <q-select dense :options="['AND', 'NOT', 'OR']" style="width: 65%" v-model="item.logic" ></q-select> -->
          <q-select dense label-slot v-model="item.type" :options="fieldsTable.others" style="width: 65%" option-label='desc' option-value='exp'>
            <div slot="label" style="margin-bottom: 30px">请选择相关筛选条件</div>
          </q-select>
        </div>
        <div style="width: 8%"></div>
      <div class="fieldInput" style="width: 30%;display: flex;align-items: flex-end">
          <q-input v-model="item.value" :placeholder="item.type ? (item.type.placeholder || '请输入相关内容') : '请输入相关内容'" style="width: 100%" dense />
          <div style='width: 10px'></div>
          <span class="material-icons" style="font-size: 26px;color: #bbbaba;cursor: pointer" @click="remove('others', index)">remove</span>
      </div>
      </div>
    </div>

    <!-- 控制台 -->
    <div class="control">
      <div style="width: 70%"></div>
      <div><q-btn color="secondary" @click="generate" label="生成检索式" /></div>
      <div style="width: 20px"></div>
      <div class="clear"><q-btn color="white" @click="clear" text-color="black" label="清空" /></div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      fieldsTable: {
        // 关键字
        keyword: [
          {
            exp: "ti:",
            desc: "标题",
            placeholder: '如：一种行车记录仪'
          },
          {
            exp: "eti:",
            desc: "英文标题",
            placeholder: '如：Notebook Computer'
          },
          {
            exp: "cti:",
            desc: "中文标题",
            placeholder: '如：一种行车记录仪'
          },
          {
            exp: "ab:",
            desc: "摘要",
            placeholder: '如：本发明公开了一种行车记录仪，包括车身以及所述车身内部设置有的第一空腔，第一空腔底部在车身上固定设置有...'
          },
          {
            exp: "eab:",
            desc: "英文摘要",
            placeholder: '如：A notebook computer includes a connecting portion, a first display screen portion, a hinge structure and a computer mainframe.'
          },
          {
            exp: "cab:",
            desc: "中文摘要",
            placeholder: '如：本发明公开了一种行车记录仪，包括车身以及所述车身内部设置有的第一空腔，第一空腔底部在车身上固定设置有...'
          },
          {
            exp: "cl:",
            desc: "权利要求",
            placeholder: '如：电动汽车 OR 充电桩'
          },
          {
            exp: "ecl:",
            desc: "英文权利要求",
            placeholder: '如：Electric vehicle or charging pile'
          },
          {
            exp: "d:",
            desc: "说明书",
            placeholder: '如：本实用新型涉及新能源电动汽车交流充电桩技术领域,具体为一种落地式电动汽车交流充电桩。'
          },
          {
            exp: "eds:",
            desc: "英文说明书",
            placeholder: '如：The utility model relates to the technical field of AC charging piles for new energy electric vehicles, in particular to a floor type AC charging pile for electric vehicles.            '
          },
          {
            exp: "ceti:",
            desc: "标题中文和英文",
            placeholder: '如：一种行车记录仪 '
          },
          {
            exp: "cets:",
            desc: "标题与摘要的中英文",
            placeholder: '如：本发明公开了一种行车记录仪,包括车身以及所述车身内部设置有的第一空腔,第一空腔底部在车身上固定设置有...'
          },
          {
            exp: "cetsc:",
            desc: "标题、摘要、权利要求的中英文",
            placeholder: '如：本发明公开了一种行车记录仪,包括车身以及所述车身内部设置有的第一空腔,第一空腔底部在车身上固定设置有...'
          }
        ],
        // 名称与地址
        nameAndAddress: [
          {
            exp: "ap:",
            desc: "申请人",
            placeholder: '如：张三'
          },
          {
            exp: "fap:",
            desc: "第一申请人",
            placeholder: ' 如：张三'
          },
          {
            exp: "addr:",
            desc: "申请人地址",
            placeholder: '如：北京市'
          },
          {
            exp: "aee:",
            desc: "专利权人",
            placeholder: '如：张三'
          },
          {
            exp: "caee:",
            desc: "当前专利权人",
            placeholder: '如：张三'
          },
          {
            exp: "in:",
            desc: "发明人",
            placeholder: '如：张三'
          },
          {
            exp: "ag:",
            desc: "代理机构",
            placeholder: '如：张三'
          },
          {
            exp: "at:",
            desc: "代理人",
            placeholder: '如：张三' 
          }
        ],
        // 号码
        number: [
          {
            exp: "n:",
            desc: "号码",
            placeholder: '如：CN201821212266.2'
          },
          {
            exp: "dn:",
            desc: "公开（公告）号",
            placeholder: '如：CN205040984U'
          },
          {
            exp: "an:",
            desc: "申请号",
            placeholder: '如：CN201821212266.2'
          },
          {
            exp: "pr:",
            desc: "优先权号",
            placeholder: '如：WO11078229'
          }
        ],
        // 日期
        date: [
          {
            exp: "dd:",
            desc: "公开（公告）日期",
            placeholder: '如：2021-01-02'
          },
          {
            exp: "ad:",
            desc: "申请日期",
            placeholder: '如：2021-01-02'
          }
        ],
        // 分类
        category: [
          {
            exp: "ipc:",
            desc: "国际IPC分类",
            placeholder: '如：A47K4/00'
          },
          {
            exp: "ipc-section:",
            desc: "IPC分类-部",
            placeholder: '如：A'
          },
          {
            exp: "ipc-class:",
            desc: "IPC分类-大类",
            placeholder: '如：A47'
          },
          {
            exp: "ipc-subclass:",
            desc: "IPC分类-小类",
            placeholder: '如：A47K'
          },
          {
            exp: "ipc-group:",
            desc: "IPC分类-大组",
            placeholder: '如：A47K4'
          },
          {
            exp: "ipc-subgroup:",
            desc: "IPC分类-小组",
            placeholder: '如：A47K4/00'
          },
          {
            exp: "ipc-main:",
            desc: "IPC主分类",
            placeholder: '如：B07B4/04'
          },
          {
            exp: "ipcm-section:",
            desc: "IPC主分类-部",
            placeholder: '如：B'
          },
          {
            exp: "ipcm-class:",
            desc: "IPC主分类-大类",
            placeholder: '如：B07'
          },
          {
            exp: "ipcm-subclass:",
            desc: "IPC主分类-小类",
            placeholder: '如：B07B'
          },
          {
            exp: "ipcm-group:",
            desc: "IPC主分类-大组",
            placeholder: '如：B07B4'
          },
          {
            exp: "ipcm-subgroup:",
            desc: "IPC主分类-小组",
            placeholder: ' 如：B07B4/04'
          },
          {
            exp: "uspc:",
            desc: "USPC分类号",
            placeholder: '如：US08/776607'
          },
          {
            exp: "uspcc:",
            desc: "USPC大类",
            placeholder: '如：US08'
          },
          {
            exp: "fi:",
            desc: "日本FI分类",
            placeholder: '如：F24F1/00,371@B'
          },
          {
            exp: "ft:",
            desc: "日本F-term分类",
            placeholder: '如：L051/BC02'
          },
          {
            exp: "loc:",
            desc: "洛迦诺分类",
            placeholder: '如：05-01'
          },
          {
            exp: "cpc:",
            desc: "CPC联合专利分类",
            placeholder: '如：B82Y35'
          }
        ],
        // 状态
        status: [
          {
            exp: "ls:",
            desc: "专利有效性",
            placeholder: '如：有效专利'
          },
          {
            exp: "currentStatus:",
            desc: "当前法律状态",
            placeholder: '如：授权'
          }
        ],
        // 其他
        others: [
          {
            exp: "type:",
            desc: "专利类型",
            placeholder: '如：发明'
          },
          {
            exp: "cc:",
            desc: "国家代码",
            placeholder: '如：中国'
          },
          {
            exp: "acc:",
            desc: "申请人国家代码",
            placeholder: '如：中国'
          },
          {
            exp: "kc:",
            desc: "文献类型",
            placeholder: '如：一次专利文献'
          },
          {
            exp: "lang:",
            desc: "语言",
            placeholder: '               '
          },
          {
            exp: "apt:",
            desc: "申请人类型",
            placeholder: '如：企业'
          },
          {
            exp: "ap-zip:",
            desc: "申请人邮编",
            placeholder: '如：848400'
          },
          {
            exp: "country:",
            desc: "国家",
            placeholder: '如：中国'
          },
          {
            exp: "province:",
            desc: "省份",
            placeholder: '如：新疆维吾尔自治区'
          },
          {
            exp: "city:",
            desc: "城市",
            placeholder: '如：乌鲁木齐市'
          },
          {
            exp: "aeet:",
            desc: "专利权人类型",
            placeholder: '如：企业'
          },
          {
            exp: "caeet:",
            desc: "当前专利权人类型",
            placeholder: '如：企业'
          },
          {
            exp: "agc:",
            desc: "代理机构代码",
            placeholder: '如：北京纪凯知识产权代理有限公司 11245'
          },
          {
            exp: "legalTag:",
            desc: "法律标签",
            placeholder: '如：授权'
          },
          {
            exp: "legalEvent:",
            desc: "法律事件(转让,质押)",
            placeholder: '如：转让'
          },
          {
            exp: "maintainYears:",
            desc: "维持年份",
            placeholder: '如：10'
          },
          {
            exp: "citedCount:",
            desc: "引证数",
            placeholder: '如：10'
          },
          {
            exp: "citingCount:",
            desc: "被引证数",
            placeholder: '如：10'
          },
          {
            exp: "level:",
            desc: "评级",
            placeholder: '如：1'
          },
          {
            exp: "judgment.date:",
            desc: "裁判文书日期",
            placeholder: '如：2022-12-01'
          },
          {
            exp: "judgment.title:",
            desc: "裁判文标题",
            placeholder: '如：人民法院民事判决书'
          },
          {
            exp: "judgment.caseId:",
            desc: "裁判文书案号",
            placeholder: '如：(2014)浙法委赔字第4号'
          },
          {
            exp: "judgment.court:",
            desc: "裁判文书法院",
            placeholder: '如：浙江省高级人民法院'
          },
          {
            exp: "judgment.province:",
            desc: "裁判文书省",
            placeholder: '如：浙江省高级人民法院'
          },
          {
            exp: "judgment.city:",
            desc: "裁判文书市",
            placeholder: '如： 浙江省杭州市中级人民法院'
          },
          {
            exp: "judgment.accuser:",
            desc: "裁判文书原告",
            placeholder: '如：广东佛陶集团钻石陶瓷有限公司'
          },
          {
            exp: "judgment.defendant:",
            desc: "裁判文书被告",
            placeholder: '如：广东东鹏陶瓷股份有限公司'
          },
          {
            exp: "ady:",
            desc: "申请日期年",
            placeholder: '如：2022'
          },
          {
            exp: "adm:",
            desc: "申请日期月",
            placeholder: '如：2021-12'
          },
          {
            exp: "pctDate:",
            desc: "PCT进入国家阶段日",
            placeholder: '               '
          },
          {
            exp: "ddy:",
            desc: "公开（公告）日期年",
            placeholder: '如：2021'
          },
          {
            exp: "ddm:",
            desc: "公开(公告)日期月",
            placeholder: '如：2021-06'
          }
        ]
      },
      valueObj: {
        keyword: {
          value: '',
          type: '',
          children: []
        },
        category: {
          value: '',
          type: '',
          children: []
        },
        nameAndAddress: {
          value: '',
          type: '',
          children: []
        },
        number: {
          value: '',
          type: '',
          children: []
        },
        date: {
          value: '',
          pickerShow: false,
          type: '',
          children: []
        },
        others: {
          value: '',
          type: '',
          children: []
        }
      }
    }
  },
  methods: {
    generate () {
      // 生成
      let valueObj = JSON.parse(JSON.stringify(this.valueObj))
      let strArr = []
      for (let key in valueObj) {
      let dateFlag = key === 'date'
        let { type, children, value } = valueObj[key]
        if (!type || !value) {
          delete valueObj[key]
          continue
        }
        let fa = ''
        if (dateFlag) {
          fa = '' + type.exp + '([' + value.from + ' TO ' + value.to + '])' + ''
        } else {
          fa = '' + type.exp + value + ''
        }
        for (let i = 0; i < children.length; i++) {
          let { type, value } = children[i]
          if (!type || !value) {
            children.splice(i, 1)
            i--
            continue
          }
          if (dateFlag) {
            fa += ' ' + children[i].logic +  ' ' + type.exp + '([' + value.from + ' TO ' + value.to + '])' + ''
          } else {
            fa += ' ' + children[i].logic + ' ' + type.exp + value + ''
          }
        }
        strArr.push(fa)
      }
      let str = ''
      for (let i = 0; i < strArr.length; i++) {
        if (i !== strArr.length - 1) {
          str += '(' + strArr[i] + ') AND '
        } else {
          str += '(' + strArr[i] + ')'
        }
      }
      this.$emit('conditionGenerated', str)
    },
    clear () {
      // 清除
      this.valueObj = {
        keyword: {
          value: '',
          type: '',
          children: []
        },
        category: {
          value: '',
          type: '',
          children: []
        },
        nameAndAddress: {
          value: '',
          type: '',
          children: []
        },
        number: {
          value: '',
          type: '',
          children: []
        },
        date: {
          value: '',
          pickerShow: false,
          type: '',
          children: []
        },
        others: {
          value: '',
          type: '',
          children: []
        }
      }
    },
    addItem (e) {
      let option = {
        value: '', type: '', logic: 'AND'
      }
      if (e === 'date') {
        option.pickerShow = false
      }
      this.valueObj[e].children.push(option)
    },
    remove (e, index) {
      this.valueObj[e].children.splice(index, 1)
    }
  }
}
</script>
<style scoped>
  .out {
    padding: 20px 0 0 0;
  }
  .fieldItem {
    display: flex;
    align-items: flex-end;
  }
  .fieldItem .tip {
    font-weight: bold;
  }
  .control {
    display: flex;
    margin-top: 40px;
    /* justify-content: flex-end; */
  }
  .fieldItemChildren .child {
    display: flex;
    margin: 15px 0;
  }
</style>
